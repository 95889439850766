<template>
  <div class="app">
    <router-view />
  </div>
</template>
<script>

export default {
  name: 'App',

  data () {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  beforeUpdate () {
  },
  methods: {

  }
}
</script>
