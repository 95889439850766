<template>
  <div class="hmain">
    <div class="main-box" v-if="flag">
      <div class="box-page1">
        <img :src="img1" alt="">
        <div class="box-text">
          <div class="text-name">{{bannerList[0].headlineEn}}&nbsp;{{bannerList[0].headline}}</div>
          <div class="text-content">{{bannerList[0].content}}</div>
          <div class="box-line"></div>
          <div class="text-contenten">{{bannerList[0].contentEn}}</div>
        </div>
      </div>
      <div class="box-page2">
        <img :src="img2" alt="">
        <div class="box-text">
          <div class="text-name">{{bannerList[1].headline}}</div>
          <div class="text-enname">
            {{bannerList[1].headlineEn}}
          </div>
          <div class="text-content">{{bannerList[1].content}}</div>
          <div class="box-line"></div>
          <div class="text-contenten">{{bannerList[1].contentEn}}</div>
          <button class="view-more" @click="handleClickRouter('35')">{{h5Language=='En'?'了解更多':'View Details'}}</button>
        </div>
      </div>
      <div class="box-page3">
        <img :src="img3" alt="">
        <div class="box-text">
          <div class="text-name">{{bannerList[2].headline}}</div>
          <div class="text-enname">
            {{bannerList[2].headlineEn}}
          </div>
          <div class="text-content">{{bannerList[2].content}}</div>
          <div class="box-line"></div>
          <div class="text-contenten">{{bannerList[2].contentEn}}</div>
          <button class="view-more"  @click="handleClickRouter('36')">{{h5Language=='En'?'了解更多':'View Details'}}</button>
        </div>
      </div>
      <div class="box-page4">
        <img :src="img4" alt="">
        <div class="box-text">
          <div class="text-name">{{bannerList[3].headline}}</div>
          <div class="text-enname">
            {{bannerList[3].headlineEn}}
          </div>
          <div class="text-content">{{bannerList[3].content}}</div>
          <div class="box-line"></div>
          <div class="text-contenten">{{bannerList[3].contentEn}}</div>
          <button class="view-more"  @click="handleClickRouter('37')">{{h5Language=='En'?'了解更多':'View Details'}}</button>
        </div>
      </div>
      <div class="box-page5">
        <img :src="img5" alt="">
        <div class="box-text">
          <div class="text-name">{{bannerList[4].headline}}</div>
          <div class="text-enname">
            {{bannerList[4].headlineEn}}
          </div>
          <div class="text-content">{{bannerList[4].content}}</div>
          <div class="box-line"></div>
          <div class="text-contenten">{{bannerList[4].contentEn}}</div>
          <button class="view-more"  @click="handleClickRouter('38')">{{h5Language=='En'?'了解更多':'View Details'}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {cloumnTree, getBanner} from '../api/index'
export default {
  name: 'hMain',
  data () {
    return {
      bannerList: [],
      list:[],
      h5Language:'',
      flag:false,
      img1:'',
      img2:'',
      img3:'',
      img4:'',
      img5:'',
      img6:''
    }
  },
   mounted () {
      this.h5Language = localStorage.getItem('h5Language')||'En'
     this.cloumnTree()

  },
  created() {
    this.getBanner()
  },
  methods: {
    cloumnTree () {
      cloumnTree().then(async res => {
       this.list = res.data
      })
    },
    handleClickRouter(val){
      this.list.forEach(e=>{
        if(e.url=='about'){
          this.$router.push({path:'about',query:{anchorId:val,listId:e.id}})
        }
      })

    },
     getBanner () {
       getBanner({ cloumnId: 26 }).then(res => {
         console.log(res,22222222)
         this.img1 = res.data[0].url
         this.img2 = res.data[1].url
         this.img3 = res.data[2].url
         this.img4 = res.data[3].url
         this.img5 = res.data[4].url
         this.img6 = res.data[5].url
         this.bannerList = res.data
         this.flag = true
        console.log(res, '我是返回的res')
      })
    }
  }

}
</script>

<style lang="scss" scoped>
  .hmain{
    .main-box{
      .box-page1{
        position: relative;
        width: 100%;
        height: 448px;
        img{
          position: absolute;
          width: 100%;
          height: 452px;
          object-fit: cover;
          z-index: -1;
        }
        .box-text{
          width: 300px;
          height: 452px;
          padding-top: 139px;
          margin:  auto;
          .text-name{
            margin: 0 auto;
            margin-bottom: 6px;
            text-align: center;
            width: 260px;
            font-size: 35px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 49px;
            letter-spacing: 3px;
          }
          .text-content{
            margin: 0 auto;
            text-align: center;
            width: 263px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 22px;
            letter-spacing: 1px;
            margin-bottom: 11px;
          }
          .text-contenten{
            margin: 0 auto;
            text-align: center;
            width: 300px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 16px;
            letter-spacing: 1px;
          }
          .box-line{
            margin: 0 auto;
            margin-bottom: 11px;
            text-align: center;
            width: 170px;
            height: 1px;
            transform: scaleY(0.5);
            border: 1px solid #FFFFFF;
          }
        }
      }
      .box-page2{
        position: relative;
        width: 100%;
        height: 448px;
        img{
          position: absolute;
          width: 100%;
          height: 452px;
          object-fit: cover;
          z-index: -1;
        }
        .box-text{
          width: 300px;
          height: 452px;
          margin:  auto;
          padding-top: 80px;
          .text-name{
            margin: 0 auto;
            text-align: center;
            width: 121px;
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 37px;
            letter-spacing: 4px;
            margin-bottom: 6px;
          }
          .text-enname{
            margin: 0 auto;
            text-align: center;
            width: 249px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 28px;
            letter-spacing: 3px;
            margin-bottom: 8px;
          }
          .text-content{
            margin: 0 auto;
            text-align: center;
            width: 272px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 22px;
            letter-spacing: 1px;
            margin-bottom: 12px;
            //margin-top: 6px;
          }
          .text-contenten{
            margin: 0 auto;
            text-align: center;
            width: 300px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 16px;
            letter-spacing: 1px;
            margin-bottom: 14px;
          }
          .view-more{
            width: 89px;
            height: 32px;
            border-radius: 18px;
            border: 1px solid #FFFFFF;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            margin: 0 auto;
          }
          .box-line{
            margin: 0 auto;
            text-align: center;
            width: 170px;
            height: 1px;
            border: 1px solid #FFFFFF;
            transform: scaleY(0.5);
            margin-bottom: 12px;
          }
        }
      }
      .box-page3{
        position: relative;
        width: 100%;
        height: 452px;
        img{
          position: absolute;
          width: 100%;
          height: 452px;
          object-fit: cover;
          z-index: -1;
        }
        .box-text{
          width: 300px;
          height: 452px;
          margin:  auto;
          padding-top: 98px;
          .text-name{
            margin: 0 auto;
            text-align: center;
            width: 121px;
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #414141;
            line-height: 37px;
            letter-spacing: 4px;
            margin-bottom: 6px;
          }
          .text-enname{
            margin: 0 auto;
            text-align: center;
            width: 245px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #414141;
            line-height: 28px;
            margin-bottom: 8px;
            letter-spacing: 3px;
          }
          .text-content{
            margin: 0 auto;
            text-align: center;
            width: 272px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #414141;
            line-height: 22px;
            letter-spacing: 1px;
            margin-bottom: 12px;
          }
          .text-contenten{
            margin: 0 auto;
            text-align: center;
            width: 304px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #414141;
            line-height: 16px;
            letter-spacing: 1px;
            margin-bottom: 14px;
          }
          .view-more{
            width: 89px;
            height: 32px;
            border-radius: 18px;
            border: 1px solid #414141;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #414141;
            line-height: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            margin: 0 auto;
          }
          .box-line{
            margin: 0 auto;
            text-align: center;
            width: 170px;
            height: 1px;
            border: 1px solid #414141;
            margin-bottom: 12px;
            transform: scaleY(0.5);
          }
        }
      }
      .box-page4{
        position: relative;
        width: 100%;
        height: 452px;
        img{
          position: absolute;
          width: 100%;
          height: 452px;
          object-fit: cover;
          z-index: -1;
        }
        .box-text{
          width: 300px;
          height: 452px;
          margin:  auto;
          padding-top: 98px;
          .text-name{
            margin: 0 auto;
            text-align: center;
            width: 121px;
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #414141;
            line-height: 37px;
            letter-spacing: 4px;
            margin-bottom: 6px;
          }
          .text-enname{
            margin: 0 auto;
            text-align: center;
            width: 313px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #414141;
            line-height: 28px;
            letter-spacing: 3px;
            margin-bottom: 8px;
          }
          .text-content{
            margin: 0 auto;
            text-align: center;
            width: 272px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #414141;
            line-height: 22px;
            letter-spacing: 1px;
            margin-bottom: 12px;
          }
          .text-contenten{
            margin: 0 auto;
            text-align: center;
            width: 304px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #414141;
            line-height: 16px;
            letter-spacing: 1px;
            margin-bottom: 14px;
          }
          .view-more{
            width: 89px;
            height: 32px;
            border-radius: 18px;
            border: 1px solid #414141;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #414141;
            line-height: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            margin: 0 auto;
          }
          .box-line{
            margin: 0 auto;
            text-align: center;
            width: 170px;
            height: 1px;
            border: 1px solid #414141;
            margin-bottom: 12px;
            transform: scaleY(0.5);
          }
        }
      }
      .box-page5{
        position: relative;
        width: 100%;
        height: 452px;
        img{
          position: absolute;
          width: 100%;
          height: 452px;
          object-fit: cover;
          z-index: -1;
        }
        .box-text{
          width: 300px;
          height: 452px;
          margin:  auto;
          padding-top: 80px;
          .text-name{
            margin: 0 auto;
            text-align: center;
            width: 121px;
            font-size: 26px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 37px;
            letter-spacing: 4px;
            margin-bottom: 6px;
          }
          .text-enname{
            margin: 0 auto;
            text-align: center;
            width: 278px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 28px;
            letter-spacing: 3px;
            margin-bottom: 8px;
          }
          .text-content{
            margin: 0 auto;
            text-align: center;
            width: 272px;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 22px;
            letter-spacing: 1px;
            margin-bottom: 12px;
          }
          .text-contenten{
            margin: 0 auto;
            text-align: center;
            width: 304px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 16px;
            letter-spacing: 1px;
            margin-bottom: 14px;
          }
          .view-more{
            width: 89px;
            height: 32px;
            border-radius: 18px;
            border: 1px solid #fff;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fff;
            line-height: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: none;
            margin: 0 auto;
          }
          .box-line{
            margin: 0 auto;
            text-align: center;
            width: 170px;
            height: 1px;
            border: 1px solid #FFFFFF;
            margin-bottom: 12px;
            transform: scaleY(0.5);
          }
        }
      }
    }

  }

</style>
