import Vue from 'vue'
import FastClick from 'fastclick'
import App from './App.vue'
import router from './router'

import Vant from 'vant'
import 'vant/lib/index.css'
import '@/assets/styles/index.scss'

Vue.use(Vant)

router.beforeEach((to, from, next) => {
  let h5Language = localStorage.getItem('h5Language')||'En'
  if(h5Language!='En'){
    document.title = 'Zhejiang Xinqiao Culture & Tourism Group Co. LTD'

  }else{
    document.title = '浙江鑫桥文旅集团有限公司'
  }
  next()
})


FastClick.prototype.onTouchEnd = function (event) {
  if (event.target.hasAttribute('type') && event.target.getAttribute('name') === 'userInfo') {
    return false
  }
}
FastClick.attach(document.body)
// 请求接口挂载在Vue原型上

Vue.config.productionTip = false

new Vue({
  router,

  render: h => h(App)
}).$mount('#app')
