import axios from 'axios'
// import store from '@/store'
// import router from '@/router'
// 创建axios实例

if (process.env.NODE_ENV == 'development') {
  axios.defaults.baseURL = 'http://119.45.156.51:8082'
} else if (process.env.NODE_ENV == 'debug') {
  axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV == 'production') {
  axios.defaults.baseURL = 'http://119.45.156.51:8082'
}

axios.defaults.timeout = 10000

// request拦截器
axios.interceptors.request.use(config => {
  // Do something before request is sentconfig.headers['Content-Type'] = 'application/json';
  config.headers['Content-Type'] = 'application/json'
  // if (localStorage.getItem('access_token') && config.url.indexOf('/login') < 0) {
  //   config.headers.Authorization = 'Bearer ' + localStorage.getItem('access_token')
  // }
  return config
}, error => {
  // Do something with request error
  Promise.reject(error)
})

// respone拦截器
axios.interceptors.response.use(
  response => {
    if (response.data.code === 401 || response.data.code === 403) {
      // 登出
      // store.dispatch('FedLogout').then(() => {
      //   router.push({ path: '/login' })
      // })
    }
    return response.data
  },
  error => {
    if (error.isAxiosError) {
      return {
        msg: '网络异常：请检查网络后提交'
      }
    }
    if (error.response) {
      const code = error.response.status
      if ((code === 401 || code === 403)) {
        // 登出
        // store.dispatch('FedLogout').then(() => {
        //   router.push({ path: '/login' })
        // })
      }
    } else {
      return Promise.reject(error)
    }
  }
)



/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params
      })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post (url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
