<template>
  <div class="footer">
    <div class="footer-header">
      <div class="header-left">
        <div class="left-top">
          <img class="left-line" src="../assets/home/line.png" alt="">
          <div class="left-text">{{h5Language=='En'?'分享至此页面':'Share Page'}}</div>
          <img class="left-line" src="../assets/home/line.png" alt="">
        </div>
        <div class="left-bottom">
          <img class="wb" src="../assets/home/xinlangweibo@2x.png" alt="">
          <img class="wx" src="../assets/home/wx@2x.png" alt="">
          <img class="qq" src="../assets/home/QQ@2x.png" alt="">
        </div>
      </div>
      <div class="header-right" @click="handleClickWXDia">
        {{h5Language=='En'?'官方微信公众号':'Official Accounts'}}
      </div>
    </div>
    <div class="footer-info">
      <div> {{h5Language=='En'?'地址：杭州市拱墅区环城北路139号杭州国际创意中心A座6楼':'Address: 6th floor, Building A, HangzhouInternational Creative Center, No. 139 Huancheng North Road, Gongshu District, Hangzhou City'}}
      </div>
      <div class="info-center" :class="h5Language=='En'?'':'flex-col'">
        <div class="phone" :class="h5Language=='En'?'':'wid_100'">{{h5Language=='En'?'电话：0571-87212133':'For more information, call 0571-87212133'}}</div>
        <div class="fax" :class="h5Language=='En'?'':'wid_100'">{{h5Language=='En'?'传真：310018':'Fax: 310018'}}</div>
        <div class="e-mail" :class="h5Language=='En'?'':'wid_100'">{{h5Language=='En'?'公司邮编：310018':'Company Postcode: 310018'}}</div>
      </div>
      <div>{{h5Language=='En'?'备案证书号':'Record Certificate No.'}}: <a href="https://beian.miit.gov.cn" style="color: #fff">  {{h5Language=='En'?'浙ICP备2022001883号':'Zhejiang ICP 2022001883'}}</a></div>
    </div>
    <van-dialog v-model="wxDia" :show-confirm-button="false">
      <div class="dia-content">
        <img @click="handleCloseDia" class="content-close" src="../assets/home/cuowu@2x.png" alt="">
        <img class="content-logo" src="../assets/home/logo2@2x.png" />
        <img class="qrcode" src="../assets/home/qrcode@2x.png" alt="">
        <div class="content-bottom">{{h5Language=='En'?'关注公众号':'Official Accounts'}}</div>
      </div>

    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'footer',
  data () {
    return {
      wxDia: false,
      h5Language:'En'
    }
  },
  mounted() {
    this.h5Language = localStorage.getItem('h5Language')||'En'
  },
  methods: {
    handleClickWXDia () {
      this.wxDia = true
    },
    handleCloseDia () {
      this.wxDia = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer{
    width: 100%;
    height: auto;
    background: #000000;
    padding: 20px 22px;
    .dia-content{
      position: relative;
      width: 308px;
      height: 293px;
      background: #FFFFFF;
      border-radius: 4px;
      padding-top: 28px;
      .content-close{
        position: absolute;
        width: 12px;
        height: 12px;
        top: 18px;
        right: 18px;
      }
      .content-logo{
        width: 120px;
        height: 28px;
        margin: 0 auto;
        margin-bottom: 32px;
        display: block;
      }
      .qrcode{
        width: 143.55px;
        height: 144.45px;
        margin: 0 auto;
        display: block;
        margin-bottom: 18px;
      }
      .content-bottom{
        text-align: center;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #4A4A4A;
        line-height: 17px;
        margin: 0 auto;
      }
    }
    //.van-dialog__footer{
    //  display: none;
    //}
    .footer-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header-left{
        width: 120px;
        .left-top{
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left-line{
            width: 20px;
            height: 1px;
          }
          .left-text{
            width: 75px;
            height: 14px;
            font-size: 10px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 14px;
            text-align: center;
          }
        }
        .left-bottom{
          margin-top: 11px;
          display: flex;
          width: 120px;
          justify-content: space-around;
          align-items: center;
          .wb{
            width: 20px;
            height: 16px;
          }
          .wx{
            width: 20px;
            height: 17px;
          }
          .qq{
            width: 14px;
            height: 17px;
          }
        }
      }
      .header-right{
        width: 117px;
        height: 30px;
        border-radius: 4px;
        border: 1px solid #979797;
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .footer-info{
      font-size: 10px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 14px;
      margin-top: 28px;
      .info-center{
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        //align-items: center;
        //justify-content: space-between;
        margin: 10px 0;

        .phone{
          //width: 120px;
          height: 14px;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 14px;
        }
        .fax{
          width: 80px;
          height: 14px;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 14px;
        }
        .e-mail{
          width: 100px;
          height: 14px;
          font-size: 10px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 14px;
        }
      }
    }
    .flex-col{
      display:flex;
      flex-direction: column;
      .wid_100{
        width: 100% !important;
      }
    }
  }
</style>
