import { get } from './../utils/fetch'

// 获取栏目树
export const cloumnTree = params => get('website/cloumnTree', params)
// 获取banner图
export const getBanner = params => get('website/getBanner', params)
//根据目录查询文章列表
export const getArticle = params => get('website/getArticle', params)
//根据目录查询文章精选
export const getArticleChoose = params => get('website/getArticleChoose', params)
//获取公司奖项列表
export const getPrizeList = params => get('website/getPrizeList', params)
//获取公司子公司列表
export const getSubsidiaryList = params => get('website/getSubsidiaryList', params)
//根据id查询文章详情
export const getArticleById = params => get('website/getArticleById', params)
//增加点赞数
export const addChick = params => get('website/chick', params)

