<template>
  <div class="contain">
    <div class="header">
      <img @click="hanleClickGoHome" class="header-left" src="../assets/home/logo2@2x.png" alt="">
      <div class="header-right">
        <div @click="handleClickChangeLanguage" v-if="routerName!='/details'">
         {{h5Language}}
        </div>
        <img @click="handleClickRouter" :class="routerName!='/details'?'':'mt_40'" src="../assets/home/slide@2x.png" alt="">
      </div>
    </div>
  </div>

</template>

<script>

export default {
  name: 'header',
  data () {
    return {
      cloumnDia: false,
      columnList: [],
      h5Language:'',
      routerName:''
    }
  },
  mounted () {
    this.h5Language = localStorage.getItem('h5Language')||'En'
  },
  watch:{
    $route: {
      handler: function (val,oldVal) {
        this.routerName = val.path
        console.log(val,oldVal,'我是返回的log')
      },
      immediate: true
    }
  },
  methods: {
    handleClickRouter () {
      this.$router.push({ path: '/slide' })

    },
    hanleClickGoHome () {
      this.$router.push({ path: '/' })
    },
    handleClickChangeLanguage(){
      if(this.h5Language =='En'){
        localStorage.setItem('h5Language','中')
        this.h5Language = '中'
      }else{
        localStorage.setItem('h5Language','En')
        this.h5Language = 'En'
      }
      location.reload();
    }
  }
}
</script>

<style lang="scss" scoped>
  //.contain{
  //  z-index: 999;
  //  position: absolute;
  //  top: 0;
  //  right: 0;
  //  //position: relative;
  //}
  .header{
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    height: 50px;
    width: 100%;
    background: #FFFFFF;
    padding: 14px 20px;
    display: flex;
    justify-content: space-between;

    .header-left{
      width: 95px;
      height: 22px;
    }
    .header-right{
      width: 64px;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: space-between;
      div{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #002E73;
        line-height: 22px;
        display: inline-block;
      }
      img{
        width: 24px;
        height: 18px;
        line-height: 22px;
      }
    }
    .mt_40{
      margin-left: 40px;
    }
  }

</style>
