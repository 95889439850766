import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/',
    name: 'Home页面',
    meta: { title: 'Home页面' },
    component: Home
  },
  {
    path: '/slide', // 关于新桥
    name: 'slide',
    component: () => import('../views/Slide'),
    meta: {
      title: '联系我们' // 页签标题
    }
  },
  {
    path: '/travel',
    name: 'travel',
    component: () => import('../views/travel'),
    meta: {
      title: '鑫桥故事' // 页签标题
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/about'),
    meta: {
      title: '核心事业' // 页签标题
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news'),
    meta: {
      title: '新闻洞察'
    }
  },
  {
    path: '/benefit',
    name: 'benefit',
    component: () => import('../views/benefit'),
    meta: {
      title: '与爱同行'
    }
  },
  {
    path: '/ours',
    name: 'ours',
    component: () => import('../views/ours'),
    meta: {
      title: '联系我们'
    }
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../components/details'),
    meta: {
      title: '联系我们'
    }
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/companyDetail'),
    meta: {
      title: '简介'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
