<template>
  <div class="home">
    <Header />
    <HMain />
    <Footer />
  </div>
</template>

<script>
import Header from '../components/header'
import HMain from '../components/hMain'
import Footer from '../components/footer'
export default {
  name: 'Home',
  components: {
    Header,
    HMain,
    Footer
  },
  data () {
    return {
    }
  },
  created () {

  },
  mounted () {

  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>

</style>
